import React, { useCallback, useEffect, useState } from 'react';

import { Auth } from '../tools/auth';
import { Tenant } from './types';
import { fetchTenants } from '../tools/fetch';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    title: {
      marginRight: theme.spacing(2),
    },
    tenantSelector: {
      color: 'white',
    },
    divider: {
      flexGrow: 1,
    },
  }),
);

interface Props {
  auth: Auth;
  authenticated: boolean;
  selectedTenant: string|null;
  setSelectedTenant: (id: string) => void;
}

export const TopBar = ({auth, authenticated, selectedTenant, setSelectedTenant}: Props) =>  {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string|null>(null)
  const [tenants, setTenants] = useState<Array<Tenant>>()

  useEffect(() => {
    if (!authenticated) {
      return
    }
    fetchTenants()
    .then(
      resp => {
        if (resp.status !== 200) {
          const msg = "could not request tenants, got response " + resp.status;
          console.log(msg);
          setError(msg);
        } else {
        return resp.json();
        }
      })
    .then(response => {
      const items = response.filter((x: any) => x.name.length > 0); // pop the one empty value
      setTenants(items as Tenant[]);
      setIsLoading(false);
    })
    .catch(error => {
      console.log(error);
      setError(error);
    });
  }, [authenticated]);

  const classes = useStyles();

  const handleTenantChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setSelectedTenant(event.target.value as string);
    }, [setSelectedTenant]
  );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <AccountCircleIcon className={classes.icon} />
          <Typography variant="h6" className={classes.title}>
            Vem Frontend
          </Typography>
          {authenticated &&
          <>
            <Select
              className={classes.tenantSelector}
              native
              value={selectedTenant}
              onChange={handleTenantChange}
            >
              <option value="">Select a tenant</option>
              { tenants && tenants.map((d) => (
                <option key={d.name} value={d.name}>{d.name}</option>
              )) }
            </Select>
            {isLoading &&
              <CircularProgress />
            }
            {
              error && <p>COULD NOT LOAD TENANTS</p>
            }
            <div className={classes.divider}></div>
            <Button onClick={auth.logout} color="inherit">Logout</Button>
          </>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}
