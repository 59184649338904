import React, {useCallback, useEffect, useState} from "react";
import { default as ReactJson } from 'react-json-view';
import _ from "lodash";

import { fetchFullProfileByEmail } from "../tools/fetch";

interface Props {
  selectedTenant: string;
}

export const ProfileSearch = (props: Props) =>  {
  const [searchText, updateSearchText] = useState("");
  const [error, setError] = useState<string|null>(null);
  const [notFound, setNotFound] = useState(false);
  const [profile, setProfile] = useState<any>(null);
  
  useEffect(
    () => {
      // when selectedTenant changes, reset this component
      updateSearchText('');
      setProfile(null);
      setNotFound(false);
      setError(null);
    }, [props.selectedTenant],
  );

  // eslint-disable-next-line
  const searchTextChangeHandler = useCallback(
    _.debounce(searchText => {
      console.log(searchText);
      setNotFound(false);
      setError(null);
      setProfile(null);
    }, 200), []
  );

  useEffect(
    () => {
      if (searchText) {
        searchTextChangeHandler(searchText);
      }
    },
    [searchText, searchTextChangeHandler]
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      fetchFullProfileByEmail(props.selectedTenant, searchText)
        .then(resp => {
          if (resp.status !== 200 && resp.status !== 404) {
              const msg = "could not request profile, got response " + resp.status;
              console.log(msg);
              setError(msg);
          }
          return resp.json()
        })
        .then(response => {
          if (response.code) {
            if (response.code === "NOT_FOUND") {
              setNotFound(true);
            } else {
              if (response.message) {
                setError(response.message);
              } else {
                setError(response.code);
              }
            }
          } else {
            setProfile(response as Object);
          }
        }).catch(error => {
          console.log("error", error);
          setError(error);
        })
    }, [props.selectedTenant, searchText]
  );

  return (
    <div className="searchbar">
      <form onSubmit={ handleSubmit }>
        <input
          type="text"
          placeholder={'Search on email address...'}
          onChange={ e => updateSearchText(e.target.value) }
          value={ searchText }
        />
        <button type="submit">Search</button>
      </form>
      { error && <p>ERROR: {error} </p>}
      { notFound && <p>
          Could not find user profile for { searchText } in tenant { props.selectedTenant}.
      </p>}
      { profile && <ReactJson
       src={ profile }
       name={ null }
       indentWidth={ 2 }
       collapsed={ false }
       displayObjectSize={false}
       displayDataTypes={false}/>}
    </div>
  )
}
