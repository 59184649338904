import React, { useState, useEffect } from 'react';

import { Auth } from '../tools/auth';
import { ProfileSearch } from './ProfileSearch';
import { TopBar } from './TopBar';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';

interface Props {
  auth: Auth;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyStateGrid: {
      minHeight: '40vh',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    emptyStateIcon: {
      fontSize: 100,
      opacity: 0.8,
    },
    emptyStateTitle: {
      color: '#777777',
    },
    emptyStateSubtitle: {
      color: 'grey',
    },
  }),
);

export const App = (props: Props) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState(localStorage.getItem('tenant'))

  const auth = props.auth
  useEffect(() => {
    const loggedIn = auth && auth.isAuthenticated();
    if (!loggedIn) {
      auth.handleAuthentication();
    }
    setAuthenticated(loggedIn)
  }, [auth])

  useEffect(() => {
    // Remember the selected tenant for next login or page refresh.
    if (selectedTenant !== null) {
      localStorage.setItem('tenant', selectedTenant)
    }
  }, [selectedTenant])

  const saveUrlAndLogin = () => {
    localStorage.setItem('user_url', window.location.pathname);
    auth.login();
  }

  const classes = useStyles();
  return (
    <div className="app-root">
      <TopBar auth={props.auth} authenticated={authenticated} selectedTenant={selectedTenant} setSelectedTenant={setSelectedTenant} />
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" style={{ marginTop: '30px'}}>
          {authenticated && selectedTenant !== null ?
            <ProfileSearch selectedTenant={selectedTenant} />
            :
            <Grid className={classes.emptyStateGrid} container spacing={0} direction="column" alignItems="center" justify="center">
                <LockIcon className={classes.emptyStateIcon} />
                <Typography variant="h4" className={classes.emptyStateTitle}>
                  Not Logged In
                </Typography>
                <Typography variant="subtitle1" className={classes.emptyStateSubtitle}>
                  Please login first.
                </Typography>
                <Button variant="outlined" color="primary" onClick={() => saveUrlAndLogin()}>Login</Button>
            </Grid>
          }
        </Container>
      </React.Fragment>
    </div>
  );
}
