const getBaseURL = () => {
  const isProduction = window.location.hostname.includes('volumental.com');
  return isProduction ? "https://vem.volumental.com" : "https://vem.volumental.dev";
}

const fetchFromVem = (path: string, method: string) => {
  const baseURL = getBaseURL()
  const url = baseURL + path;

  return fetch(
    url,
    {
      method: method,
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
    }
  )
}

export const fetchTenants = () => {
  return fetchFromVem('/v1/tenants', 'GET')
}

export const fetchFullProfileByEmail = (tenant: string, emailAddress: string) => {
  const path = '/v1/' + tenant + '/profiles/full_profile/' + emailAddress;
  return fetchFromVem(path, 'GET')
}
