import * as React from "react";
import * as ReactDOM from "react-dom";

import { Auth } from './tools/auth';

import { App } from "./components/App";

const auth = new Auth();

ReactDOM.render(
  <App auth={auth} />,
  document.getElementById("root")
);
