import * as auth0 from 'auth0-js';

/*
  This code is based on: https://auth0.com/docs/quickstart/spa/react
  Please don't change it unless there is a change in auth0 recommendation!
*/

export class Auth {
  private auth0: auth0.WebAuth;

  constructor() {
    const callback_url = window.location.href
    this.auth0 = new auth0.WebAuth({
      domain: 'volumental.eu.auth0.com',
      clientID: 'EIVszuIou05Pbp8zYYZ66T1I5Np7aFSk', // client ID for Auth0 application: Volumental Internal Admin UIs
      redirectUri: callback_url,
      audience: 'https://internal.volumental.com',
      responseType: 'token id_token',
      scope: 'openid',
    });
  }

  handleAuthentication = () => {
    /* Frontend doesn't verify the token. Backend will verify on request */
    const options = { _idTokenVerification: false };
    this.auth0.parseHash(options, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      }
    });
  }

  login = () => {
    this.auth0.authorize();
  }

  setSession(authResult: auth0.Auth0DecodedHash) {
    // Set the time that the access token will expire at
    if (authResult.expiresIn !== undefined) {
      const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
      localStorage.setItem('expires_at', expiresAt);
    } else {
      localStorage.setItem('expires_at', '');
    }
    localStorage.setItem('access_token', authResult.accessToken || '');
    window.location.replace('/');
  }

  logout = () => {
    console.log("logout");
    // Clear access token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    window.location.reload(true);
  }

  isAuthenticated = () => {
    // Workaround for lack of localStorage during testing.
    if (typeof localStorage === 'undefined') {
      return false;
    }
    // Check whether the current time is past the access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') || 'null');
    if (typeof expiresAt === 'number') {
      return new Date().getTime() < expiresAt;
    }
    return false;
  }
}
